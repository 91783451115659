.App {
  text-align: center;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.merry {
  font-size: 2em;
  font-weight: 1000;
  color: black;
  top: 450px;
}

.front {
  z-index: 3;
}
.middle {
  z-index: 2;
}

.back {
  z-index: 1;
}

img {
  width: 375px;
  position: relative;
}

.overlay {
  position: absolute;
}

h1 {
  position: absolute;
  left: 53px;
  margin: 0;
}  */
